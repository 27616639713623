import React, { FC, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { SectionTitle } from 'src/common/components/SectionTitle';
import { CourseCard } from 'src/common/components/CourseCard';
import { SwiperSlide } from 'swiper/react';
import { range } from 'ramda';
import { media } from 'src/config/breakpoints';
import { Button } from 'src/common/components/Button';
import { EmptyCourseCard } from 'src/common/components/EmptyCourseCard';
import { CourseCardSwiper, NavigationButton } from 'src/common/components/CourseCardSwiper';
import { rem } from 'polished';
import { CourseCardFeaturedSkeleton } from 'src/common/components/CourseCardFeaturedSkeleton';
import { CourseCardSkeleton } from 'src/common/components/CourseCardSkeleton';
import { CourseExcerpt } from 'src/common/models/courseExcerpt';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';
import { CourseViewType } from 'src/state/courseListing/courseListingReducer';
import { FreeMode, SwiperOptions } from 'swiper';
import { SearchRoute } from 'src/search/routes';

const ButtonContainer = styled.div`
  text-align: center;
`;

const Card = styled.div<{ featured?: boolean }>`
  width: ${({ featured }) => (featured ? rem(245) : rem(212))};

  ${media.w.min.px992(css`
    padding: 0 0.75rem;
    width: auto;
  `)};
`;

const CourseCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  row-gap: 1.5rem;
  margin: 0 -0.75rem;
  padding-bottom: 1.875rem;
  margin-top: 1.625rem;

  ${Card} > div {
    height: 100%;
  }
`;

const Desktop = styled.div`
  ${media.w.max.px992(css`
    display: none;
  `)};
`;

const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: -0.5rem;
  text-align: center;

  ${media.w.min.px992(css`
    margin: 0 0 1.25rem 1.25rem;
    text-align: left;
  `)};
`;

const StyledCourseCardSwiper = styled(CourseCardSwiper)`
  ${media.w.min.px992(css`
    display: none;
  `)};

  ${NavigationButton} {
    display: none;
  }

  .swiper-scrollbar {
    ${media.w.min.px992(css`
      display: none;
    `)};
  }

  .swiper-slide {
    width: auto;
  }
`;

const swiperOptions: SwiperOptions = {
  centeredSlides: true,
  centeredSlidesBounds: true,
  freeMode: {
    enabled: true,
  },
  modules: [FreeMode],
  slidesPerView: 'auto',
};

export interface HomepageLatestCoursesDisplayProps {
  featuredButtonText: string;
  items?: CourseExcerpt[];
  sectionTitle: string;
  viewType: CourseViewType;
}

export const HomepageLatestCoursesDisplay: FC<HomepageLatestCoursesDisplayProps> = ({
  featuredButtonText,
  items,
  sectionTitle,
  viewType,
}) => {
  const [featured, setFeatured] = useState<CourseExcerpt | undefined>(undefined);
  const [slice, setSlice] = useState<CourseExcerpt[] | undefined>(undefined);

  const displayShowMoreButton = useMemo(() => {
    return (
      viewType !== CourseViewType.CourseBundle ||
      (viewType === CourseViewType.CourseBundle && items && items?.length > 6)
    );
  }, [items, viewType]);

  useEffect(() => {
    if (items && items.length > 0) {
      if (viewType === CourseViewType.CourseBundle) {
        setSlice(items.slice(0, 6));
      } else {
        setFeatured(items[0]);
        setSlice(items.slice(1, 4));
      }
    }
  }, [items, viewType]);

  return (
    <>
      <StyledSectionTitle>{sectionTitle}</StyledSectionTitle>

      <Desktop>
        {viewType !== CourseViewType.CourseBundle && (
          <>
            {featured ? (
              <CourseCard featured featuredBreakpoint="px992" courseExcerpt={featured} />
            ) : (
              <CourseCardFeaturedSkeleton />
            )}
          </>
        )}

        <CourseCards>
          {slice
            ? slice.map((course: CourseExcerpt) => (
                <Card key={course.courseId}>
                  <CourseCard courseExcerpt={course} />
                </Card>
              ))
            : range(0, 3).map((item, index) => (
                <Card key={index}>
                  <CourseCardSkeleton />
                </Card>
              ))}
        </CourseCards>

        {displayShowMoreButton && (
          <ButtonContainer>
            <Button kind="border" as={GatsbyLink} to={SearchRoute} state={{ viewType }}>
              {featuredButtonText}
            </Button>
          </ButtonContainer>
        )}
      </Desktop>

      <StyledCourseCardSwiper options={swiperOptions}>
        {items
          ? items.map((course: CourseExcerpt, index: number) => (
              <SwiperSlide key={course.courseId}>
                <Card featured={index === 1}>
                  <CourseCard
                    featured={index === 1}
                    featuredBreakpoint="px992"
                    courseExcerpt={course}
                  />
                </Card>
              </SwiperSlide>
            ))
          : range(0, 5).map((item, index) => (
              <SwiperSlide key={index}>
                <Card featured={index === 1}>
                  <CourseCardSkeleton />
                </Card>
              </SwiperSlide>
            ))}

        <SwiperSlide>
          <EmptyCourseCard url={SearchRoute} urlState={{ viewType }} />
        </SwiperSlide>
      </StyledCourseCardSwiper>
    </>
  );
};
