import React, { FC, useMemo } from 'react';
import { useCommonCourseCategories } from 'src/common/hooks/useCommonCourseCategories';
import { entrepreneurCategoryCode, getCategoryIdByCode } from 'src/common/models/category';
import { HomepageCategoryDetails } from 'src/public/components/homepage/HomepageCategory/HomepageCategoryDetails';
import { mapToCourseExcerpt } from 'src/common/models/elearning';
import { useElearningByCategoryQuery } from 'src/common/hooks/useElearningByCategoryQuery';
import { CourseViewType } from 'src/state/courseListing/courseListingReducer';

export const HomepageCategoryElearning: FC = () => {
  const { data: categories } = useCommonCourseCategories();

  const entrepreneurCategoryId = useMemo(
    () => getCategoryIdByCode(categories ?? [], entrepreneurCategoryCode),
    [categories],
  );

  const { data: courses } = useElearningByCategoryQuery(entrepreneurCategoryId || 0, 8, {
    enabled: !!entrepreneurCategoryId,
  });

  const courseExcerpts = useMemo(() => courses?.map(mapToCourseExcerpt), [courses]);

  if (courseExcerpts && courseExcerpts.length === 0) {
    return null;
  }

  return (
    <HomepageCategoryDetails
      categoryId={entrepreneurCategoryId}
      courseExcerpts={courseExcerpts!}
      viewType={CourseViewType.Elearning}
    />
  );
};
