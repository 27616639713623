import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { HomepageLatestCoursesDisplay } from 'src/public/components/homepage/HomepageLatestCourses/HomepageLatestCoursesDisplay';
import { mapToCourseExcerpt } from 'src/common/models/elearning';
import { useLatestElearningsQuery } from 'src/common/hooks/useLatestElearningsQuery';
import { CourseViewType } from 'src/state/courseListing/courseListingReducer';

const Container = styled.div`
  padding: 1.5625rem 0 1.875rem;
`;

export const HomepageLatestElearningCourses: FC = () => {
  const { data, isFetching } = useLatestElearningsQuery(9);

  const items = useMemo(() => data && data.map((a) => mapToCourseExcerpt(a)), [data]);

  if (!isFetching && (!items || items.length === 0)) {
    return null;
  }

  return (
    <Container>
      <HomepageLatestCoursesDisplay
        featuredButtonText="Pokaż więcej e-learningów"
        items={items}
        sectionTitle="Najnowsze e-learningi"
        viewType={CourseViewType.Elearning}
      />
    </Container>
  );
};
