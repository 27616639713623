import { CourseExcerpt } from 'src/common/models/courseExcerpt';
import { CourseType } from 'src/common/models/courseType';
import { StoredImageDTO } from 'src/common/models/image';
import { pipe } from 'fp-ts/function';
import { fromNullable, map as mapO, toUndefined } from 'fp-ts/Option';
import { SpeakerDTO } from 'src/common/models/speaker';
import { PartnerDTO } from 'src/common/models/partner';
import { getCourseBundleRoute } from 'src/courseBundle/routes';

export interface CourseBundleDTO {
  id: number;
  image: StoredImageDTO;
  partners: PartnerDTO[];
  sections: SectionDTO[];
  seoDescription: string;
  slug: string;
  speakers: SpeakerDTO[];
  status: CourseBundleStatus;
  title: string;
}

interface SectionDTO {
  content: string;
  heading: string;
  id: string;
}

export interface CourseBundleBasicInfoDTO {
  createdDate: string;
  id: number;
  modifiedDate: string;
  slug: string;
  status: CourseBundleStatus;
  title: string;
}

export interface CourseBundleListItemDTO {
  id: number;
  image: StoredImageDTO;
  slug: string;
  speakers: SpeakerNameInfoDTO[];
  title: string;
}

interface SpeakerNameInfoDTO {
  firstName: string;
  id: number;
  lastName: string;
}

enum CourseBundleStatus {
  Draft = 'DRAFT',
  Public = 'PUBLIC',
}

export const mapToCourseExcerpt = (courseBundle: CourseBundleListItemDTO): CourseExcerpt => ({
  id: courseBundle.id,
  title: courseBundle.title,
  slug: courseBundle.slug,
  courseId: courseBundle.id,
  image: courseBundle.image,
  courseType: CourseType.CourseBundle,
  link: getCourseBundleRoute(courseBundle),
  elearningUserEnrollment: null,
  speaker: pipe(
    fromNullable(courseBundle.speakers[0]),
    mapO((a) => ({
      id: a.id,
      firstName: a.firstName,
      lastName: a.lastName,
      companyName: '',
    })),
    toUndefined,
  ),
});

export enum CourseBundleUserEnrollmentStatus {
  Enrolled = 'ENROLLED',
  NotEnrolled = 'NOT_ENROLLED',
}
