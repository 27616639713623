import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { CleanUnorderedList } from 'src/common/components/List';
import { colors, shadows } from 'src/config/colors';
import { TestimonialQuotemarkDesktop } from 'src/common/images/TestimonialQuotemarkDesktop';
import { TestimonialQuotemarkMobile } from 'src/common/images/TestimonialQuotemarkMobile';
import { typography } from 'src/config/typography';
import { media } from 'src/config/breakpoints';
import { layers } from 'src/config/layers';
import { TestimonialType } from 'src/public/components/homepage/config-testimonials';
import NextImage from 'next/image';

const Avatar = styled.div`
  grid-area: avatar;

  img {
    border-radius: 100%;
    width: 100%;
  }
`;

const Client = styled.div`
  align-items: center;
  display: grid;
  gap: 1.25rem;
  grid-template-areas:
    'avatar info'
    'list list';
  grid-template-columns: 6.875rem auto;

  ${media.w.min.px768(css`
    gap: 1.0625rem 4.375rem;
    grid-template-areas:
      'avatar list'
      'info info';
    grid-template-columns: 10.75rem auto;
  `)};
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 31.25rem;

  ${media.w.min.px768(css`
    max-width: 36.25rem;
  `)};
`;

const Info = styled.div`
  grid-area: info;

  ${media.w.min.px768(css`
    margin-left: 5.125rem;
  `)};
`;

const List = styled.div`
  background: ${colors.white};
  border-radius: 0.5rem;
  box-shadow: ${shadows.drop1};
  grid-area: list;
  justify-self: end;
  margin-left: 2.8125rem;
  max-width: 25rem;
  padding: 1.4375rem 0.875rem;
  position: relative;

  ${media.w.min.px768(css`
    margin-left: 0;
    max-width: none;
    padding: 2.125rem 2.25rem;
    width: 100%;
  `)};

  &::after {
    border: 0.6875rem solid transparent;
    border-left-color: ${colors.white};
    border-top-color: ${colors.white};
    height: 0;
    left: 0;
    position: absolute;
    top: 100%;
    width: 0;

    ${media.w.min.px768(css`
      content: '';
    `)};
  }

  &::before {
    background: ${colors.white};
    bottom: -0.0625rem;
    height: 0.625rem;
    left: 0;
    position: absolute;
    width: 1.3125rem;

    ${media.w.min.px768(css`
      content: '';
    `)};
  }
`;

const ListTitle = styled.div`
  font-family: ${typography.fontFamilyHeaders};
  font-size: 1rem;
  line-height: 1.375rem;
  margin-bottom: 0.5rem;
`;

const Name = styled.div`
  color: ${colors.pfp3bis};
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
`;

const StyledUnorderedList = styled(CleanUnorderedList)`
  li {
    padding-left: 1.0625rem;
    position: relative;

    &::before {
      background: ${colors.pfp3bis};
      border-radius: 100%;
      content: '';
      height: 0.375rem;
      left: 0.0625rem;
      position: absolute;
      top: 0.3125rem;
      width: 0.375rem;
    }
  }
`;

const TestimonialQuotemark = styled.div`
  position: absolute;
  z-index: ${layers.contentBottom};
`;

const TestimonialQuotemarkFirst = styled(TestimonialQuotemark)`
  left: 0.625rem;
  top: -0.375rem;

  ${media.w.min.px768(css`
    left: 2.4375rem;
    top: 1.5rem;
  `)};
`;

const TestimonialQuotemarkSecond = styled(TestimonialQuotemark)`
  bottom: -0.4375rem;
  right: 0.625rem;

  ${media.w.min.px768(css`
    bottom: -1.3125rem;
    right: 1.9375rem;
  `)};
`;

const Text = styled.div`
  color: ${colors.blue};
`;

const Quote = styled.div`
  background: ${colors.blue};
  border-radius: 0.5rem;
  box-shadow: ${shadows.drop1};
  margin-top: 1rem;
  padding: 1.25rem 0.625rem;
  position: relative;

  ${media.w.min.px768(css`
    margin-top: 1.625rem;
    padding: 3rem 2rem 2rem 7rem;
  `)};

  &::after {
    border-bottom: 0.9375rem solid ${colors.blue};
    border-left: 1.125rem solid ${colors.blue};
    border-right: 1.125rem solid transparent;
    border-top: 0.9375rem solid transparent;
    bottom: 100%;
    height: 0;
    left: 0;
    position: absolute;
    width: 0;

    ${media.w.min.px768(css`
      content: '';
    `)};
  }

  &::before {
    background: ${colors.blue};
    height: 0.625rem;
    left: 0;
    position: absolute;
    top: -1px;
    width: 2.1875rem;

    ${media.w.min.px768(css`
      content: '';
    `)};
  }
`;

const QuoteText = styled.div`
  color: ${colors.white};
  font-size: 0.875rem;
  font-style: italic;
  font-weight: 300;
  line-height: 1.1875rem;
  text-indent: 2.375rem;

  ${media.w.min.px768(css`
    text-indent: 0;
  `)};

  &::after {
    display: inline-block;
    width: 2.375rem;

    ${media.w.max.px768(css`
      content: '';
    `)};
  }
`;

interface TestimonialProps {
  testimonial: TestimonialType;
}

export const Testimonial: FC<TestimonialProps> = ({ testimonial }) => (
  <Container>
    <Client>
      <Avatar>
        <NextImage alt={testimonial.info.name} src={testimonial.avatarImg} />
      </Avatar>

      <Info>
        <Name>{testimonial.info.name}</Name>
        <Text>
          {testimonial.info.description}
          <br />
          {testimonial.info.location}
        </Text>
      </Info>

      <List>
        <ListTitle>Szkolenia pomogły mi:</ListTitle>

        <StyledUnorderedList>
          {testimonial.insightPoints.map((point, i) => (
            <li key={i}>{point}</li>
          ))}
        </StyledUnorderedList>
      </List>
    </Client>

    <Quote>
      <QuoteText>{testimonial.opinion}</QuoteText>

      <TestimonialQuotemarkFirst>
        <TestimonialQuotemarkDesktop />
        <TestimonialQuotemarkMobile />
      </TestimonialQuotemarkFirst>
      <TestimonialQuotemarkSecond>
        <TestimonialQuotemarkDesktop />
        <TestimonialQuotemarkMobile />
      </TestimonialQuotemarkSecond>
    </Quote>
  </Container>
);
