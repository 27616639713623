import {
  AuthorizedAxios,
  BaseAxios,
  MixedAxios,
  throwErrorResponse,
} from 'src/common/services/axios';
import { flatten } from 'src/lib/axios';
import {
  CourseBundleDTO,
  CourseBundleUserEnrollmentStatus,
  CourseBundleListItemDTO,
} from 'src/common/models/courseBundle';
import {
  GetInfiniteQueryFunctionContext,
  PageResultDTO,
  unwrap as unwrapPage,
} from 'src/common/models/page';
import { EventSearchCriteria } from 'src/common/models/eventSearchCriteria';
import { omit } from 'ramda';
import { stringify } from 'qs';
import { ListWrapper, unwrap } from 'src/lib/listWrapper';
import { EventListItemDTO } from 'src/common/models/event';
import { MaterialDTO } from 'src/common/models/material';
import { ElearningDTO } from 'src/common/models/elearning';
import { courseBundleEventsPageSize } from 'src/common/hooks/courseBundle/useCourseBundleEvents';
import { courseBundleElearningsPageSize } from 'src/common/hooks/courseBundle/useCourseBundleElearnings';

export const getCourseBundles = (criteria: EventSearchCriteria, page: number, pageSize: number) => {
  const searchCriteria = {
    ...omit(['categoryIds', 'levels'], criteria),
    page,
    pageSize,
  };

  return BaseAxios.get<PageResultDTO<CourseBundleListItemDTO>>(
    '/api/public/course_bundles/search',
    {
      params: searchCriteria,
      paramsSerializer: (params) => stringify(params, { arrayFormat: 'comma' }),
    },
  )
    .then(flatten)
    .catch(throwErrorResponse);
};

export const getAvailableCourses = () =>
  BaseAxios.get<PageResultDTO<CourseBundleListItemDTO>>(`/api/public/course_bundles/all`, {
    params: { pageSize: 10 },
  })
    .then(flatten)
    .then(unwrapPage)
    .catch(throwErrorResponse);

export const getCourseBundleById = (id: number) =>
  BaseAxios.get<CourseBundleDTO>(`/api/public/course_bundles/${id}`)
    .then(flatten)
    .catch(throwErrorResponse);

export const getCourseBundleEvents = (
  { pageParam, pageSize }: GetInfiniteQueryFunctionContext,
  id: number,
) =>
  BaseAxios.get<PageResultDTO<EventListItemDTO>>(`/api/public/course_bundles/${id}/events`, {
    params: { page: pageParam || 1, pageSize: pageSize ?? courseBundleEventsPageSize },
  })
    .then(flatten)
    .catch(throwErrorResponse);

export const getCourseBundleElearnings = (
  { pageParam, pageSize }: GetInfiniteQueryFunctionContext,
  id: number,
  isLoggendIn: boolean,
) => {
  const config = {
    params: { page: pageParam || 1, pageSize: pageSize ?? courseBundleElearningsPageSize },
  };
  const request = isLoggendIn
    ? MixedAxios.get<PageResultDTO<ElearningDTO>>(`/api/course_bundles/${id}/elearnings`, config)
    : BaseAxios.get<PageResultDTO<ElearningDTO>>(
        `/api/public/course_bundles/${id}/elearnings`,
        config,
      );
  return request.then(flatten).catch(throwErrorResponse);
};

export const getCourseBundleMaterials = (id: number, isLoggendIn: boolean) => {
  const request = isLoggendIn
    ? AuthorizedAxios.get<ListWrapper<MaterialDTO>>(`/api/course_bundles/${id}/materials`)
    : BaseAxios.get<ListWrapper<MaterialDTO>>(`/api/public/course_bundles/${id}/materials`);

  return request.then(flatten).then(unwrap).catch(throwErrorResponse);
};

export const getUserOngoingCourseBundles = (page: number, pageSize: number) =>
  MixedAxios.get<PageResultDTO<CourseBundleListItemDTO>>('/api/course_bundles/me/ongoing', {
    params: {
      page,
      pageSize,
    },
  })
    .then(flatten)
    .then(unwrapPage)
    .catch(throwErrorResponse);

export const getUserCompletedCourseBundles = (page: number, pageSize: number) =>
  MixedAxios.get<PageResultDTO<CourseBundleListItemDTO>>(`/api/course_bundles/me/completed`, {
    params: {
      page,
      pageSize,
    },
  })
    .then(flatten)
    .then(unwrapPage)
    .catch(throwErrorResponse);

export const signInForCourseBundle = (id: number) =>
  AuthorizedAxios.post<number>(`/api/course_bundles/${id}`).then(flatten).catch(throwErrorResponse);

export const getUserCourseBundleEnrollmentStatus = (id: number) =>
  MixedAxios.get<{ value: CourseBundleUserEnrollmentStatus }>(`/api/course_bundles/${id}/me`)
    .then(flatten)
    .catch(throwErrorResponse);
