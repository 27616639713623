import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { SectionTitle } from 'src/common/components/SectionTitle';
import { SwiperSlide } from 'swiper/react';
import { range } from 'ramda';
import { CourseCard } from 'src/common/components/CourseCard';
import { EmptyCourseCard } from 'src/common/components/EmptyCourseCard';
import { below, media, widthBreakpoints } from 'src/config/breakpoints';
import { Button } from 'src/common/components/Button';
import { useMediaQuery } from 'src/common/migration/useMediaQuery';
import { CourseCardSwiper, NavigationButton } from 'src/common/components/CourseCardSwiper';
import { CourseCardSkeleton } from 'src/common/components/CourseCardSkeleton';
import { CourseExcerpt } from 'src/common/models/courseExcerpt';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';
import { CourseViewType } from 'src/state/courseListing/courseListingReducer';
import { FreeMode, SwiperOptions } from 'swiper';
import { SearchRoute } from 'src/search/routes';

const Card = styled.div`
  width: 13.25rem;

  ${media.w.min.px992(css`
    width: auto;
  `)};
`;

const Container = styled.div`
  padding: 1.875rem 0;

  ${media.w.min.px768(css`
    display: flex;
    justify-content: center;
    padding-top: 2.5rem;
  `)}

  ${media.w.min.px992(css`
    padding-top: 0.625rem;
  `)}
`;

const Content = styled.div`
  ${media.w.min.px768(css`
    padding: 0 0.875rem;
    width: 29.75rem;
  `)};

  ${media.w.between.px992.px1200(css`
    padding: 0;
    width: 60%;
  `)}
`;

const Header = styled.div`
  text-align: center;

  ${media.w.min.px768(css`
    flex: 0 0 auto;
    padding: 4.5rem 0 0 2.5rem;
    text-align: left;
    width: 33%;
  `)}

  ${media.w.min.px992(css`
    padding-left: 0;
    padding-right: 1.5rem;
    width: 40%;
  `)}

  ${media.w.min.px1200(css`
    padding-left: 1.25rem;
    width: 33%;
  `)}
`;

const StyledButton = styled(Button)`
  ${media.w.max.px768(css`
    display: none;
  `)}
`;

const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: 0.9375rem;
`;

const StyledCourseCardSwiper = styled(CourseCardSwiper)`
  ${NavigationButton} {
    ${media.w.max.px1200(css`
      display: none;
    `)};
  }

  .swiper-scrollbar {
    ${media.w.min.px1200(css`
      display: none;
    `)};
  }

  .swiper-slide {
    width: auto;

    ${media.w.between.px992.px1200(css`
      width: 13.25rem;
    `)}
  }
`;

const Title = styled.div`
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin-bottom: -0.5rem;

  ${media.w.min.px768(css`
    margin-bottom: 3.125rem;
  `)};
`;

const swiperOptions: SwiperOptions = {
  breakpoints: {
    768: {
      centeredSlides: false,
      centeredSlidesBounds: false,
      freeMode: {
        enabled: false,
      },
      slidesPerView: 2,
    },
    992: {
      centeredSlides: true,
      centeredSlidesBounds: true,
      freeMode: {
        enabled: true,
      },
      slidesPerView: 'auto',
    },
    1200: {
      centeredSlides: false,
      centeredSlidesBounds: false,
      freeMode: {
        enabled: false,
      },
      slidesPerView: 2,
    },
  },
  centeredSlides: true,
  centeredSlidesBounds: true,
  freeMode: {
    enabled: true,
  },
  modules: [FreeMode],
  slidesPerView: 'auto',
};

export interface HomepageCategoryDetailsProps {
  categoryId: number | null;
  courseExcerpts: CourseExcerpt[];
  viewType: CourseViewType;
}

export const HomepageCategoryDetails: FC<HomepageCategoryDetailsProps> = ({
  categoryId,
  courseExcerpts,
  viewType,
}) => {
  const isMobile = useMediaQuery({ maxWidth: below(widthBreakpoints.px768) });

  return (
    <Container>
      <Header>
        <StyledSectionTitle>Dla przedsiębiorców</StyledSectionTitle>
        <Title>Sprawdź popularne szkolenia</Title>
        <StyledButton as={GatsbyLink} to={SearchRoute} state={{ categoryId, viewType }}>
          Zobacz wszystkie
        </StyledButton>
      </Header>

      <Content>
        <StyledCourseCardSwiper options={swiperOptions}>
          {courseExcerpts
            ? courseExcerpts.map((course) => (
                <SwiperSlide key={course.courseId}>
                  <Card>
                    <CourseCard courseExcerpt={course} />
                  </Card>
                </SwiperSlide>
              ))
            : range(0, 5).map((item, index) => (
                <SwiperSlide key={index}>
                  <Card key={index}>
                    <CourseCardSkeleton />
                  </Card>
                </SwiperSlide>
              ))}

          {isMobile && (
            <SwiperSlide>
              <EmptyCourseCard url={SearchRoute} urlState={{ categoryId, viewType }} />
            </SwiperSlide>
          )}
        </StyledCourseCardSwiper>
      </Content>
    </Container>
  );
};
