import { QueryFunctionContext, useInfiniteQuery, useQueryClient } from 'react-query';
import { useEffect } from 'react';
import { QueryKey } from 'src/config/queryKey';
import { useDispatch } from 'react-redux';
import { unwrap } from 'src/common/models/page';
import { useCurrentUserKeyEnhancer } from 'src/common/hooks/useCurrentUserKeyEnhancer';
import { getCourseBundleElearnings } from 'src/common/services/courseBundle';
import { courseBundleElearningsActions } from 'src/courseBundle/state/courseBundleElearnings/courseBundleElearningsActions';

export const courseBundleElearningsPageSize = 3;

export const useCourseBundleElearningsQuery = (courseBundleId?: number) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { key, isAuthenticated } = useCurrentUserKeyEnhancer([
    QueryKey.CourseBundleElearnings,
    courseBundleId,
  ]);

  useEffect(() => {
    return () => {
      queryClient.setQueryData(key, () => ({
        pages: [],
        pageParams: undefined,
      }));
    };
  }, []);

  return useInfiniteQuery(
    key,
    (context: QueryFunctionContext) =>
      getCourseBundleElearnings(context, courseBundleId!, isAuthenticated),
    {
      enabled: !!courseBundleId,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage && lastPage.totalResult > allPages.length * courseBundleElearningsPageSize
          ? allPages.length + 1
          : false;
      },
      onSuccess: (data) => {
        if (data.pages.length <= 0) {
          return;
        }

        data.pages.length === 1
          ? dispatch(courseBundleElearningsActions.setElearnings(unwrap(data.pages[0])))
          : dispatch(
              courseBundleElearningsActions.addElearnings(
                unwrap(data.pages[data.pages.length - 1]),
              ),
            );
      },
    },
  );
};
