import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { homepageViewTypeSelector } from 'src/state/homepage/homepageSelectors';
import { HomepageCategoryOnline } from 'src/public/components/homepage/HomepageCategory/HomepageCategoryOnline';
import { HomepageCategoryElearning } from 'src/public/components/homepage/HomepageCategory/HomepageCategoryElearning';
import { CourseType } from 'src/common/models/courseType';

export const HomepageCategory: FC = () => {
  const selectedViewType = useSelector(homepageViewTypeSelector);

  if (selectedViewType === CourseType.StandardCourse) {
    return <HomepageCategoryOnline />;
  } else if (selectedViewType === CourseType.ElearningCourse) {
    return <HomepageCategoryElearning />;
  } else {
    return <></>;
  }
};
