import { useQuery } from 'react-query';
import { QueryKey } from 'src/config/queryKey';
import { useCurrentUserKeyEnhancer } from 'src/common/hooks/useCurrentUserKeyEnhancer';
import { getAvailableElearnings } from 'src/common/services/elearning';

export const useLatestElearningsQuery = (limit: number) => {
  const { key } = useCurrentUserKeyEnhancer([QueryKey.AvailableElearnings, limit]);

  return useQuery(key, () => getAvailableElearnings(limit));
};
