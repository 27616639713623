import { QueryObserverOptions, useQuery } from 'react-query';
import { QueryKey } from 'src/config/queryKey';
import { useCurrentUserKeyEnhancer } from 'src/common/hooks/useCurrentUserKeyEnhancer';
import { Elearning } from 'src/common/models/elearning';
import { getAvailableElearnings } from 'src/common/services/elearning';

export const useElearningByCategoryQuery = (
  categoryId: number,
  limit = 8,
  options?: QueryObserverOptions<Elearning[]>,
) => {
  const { key } = useCurrentUserKeyEnhancer([QueryKey.AvailableElearnings, limit, { categoryId }]);

  return useQuery(key, () => getAvailableElearnings(limit, { categoryId }), {
    ...options,
  });
};
