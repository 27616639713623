import TestimonialQuotemarkDesktopSvg from 'src/assets/images/testimonial-quotemark-desktop.svg';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';

export const TestimonialQuotemarkDesktop = styled(TestimonialQuotemarkDesktopSvg)`
  height: 2.625rem;

  ${media.w.max.px768(css`
    display: none;
  `)};
`;
