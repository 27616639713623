import { getSlug, Sluggable } from 'src/common/services/slug';
import { buildPathname, buildRoute, translateSegments } from 'src/lib/router.utils';
import { setParams } from 'src/common/utils/routing';

const courseBundleIdParam = ':courseBundleId';
const courseBundleSlugParam = ':slug';

const sourceItemSegment = 'course-bundle';

const segmentsTranslations = {
  [sourceItemSegment]: 'kurs',
};

const segmentTranslator = (segments: string[]) => translateSegments(segments, segmentsTranslations);

const sourceItemRouteSegments = [sourceItemSegment, courseBundleSlugParam, courseBundleIdParam];

const translatedItemRouteSegments = segmentTranslator(sourceItemRouteSegments);

const SourceCourseBundleItemRoute = buildRoute(sourceItemRouteSegments);

const TranslatedCourseBundleItemRoute = buildRoute(translatedItemRouteSegments);

export const CourseBundleItemRoute = TranslatedCourseBundleItemRoute;

export const courseBundlePathnameTranslations = {
  [buildPathname(sourceItemRouteSegments)]: buildPathname(translatedItemRouteSegments),
};

export const courseBundleTranslatedRoutes = [
  { source: SourceCourseBundleItemRoute, translated: TranslatedCourseBundleItemRoute },
];

export const getCourseBundleRoute = (courseBundle: { id: number } & Sluggable) =>
  getCourseBundleSlugRoute(courseBundle);

export const getCourseBundleSlugRoute = (courseBundle: { id: number } & Sluggable) => {
  return setParams(courseBundleIdParam, courseBundleSlugParam)(CourseBundleItemRoute)(
    String(courseBundle.id),
    getSlug(courseBundle),
  );
};
