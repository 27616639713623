import { useQuery } from 'react-query';
import { QueryKey } from 'src/config/queryKey';
import { getAvailableCourses } from 'src/common/services/course';
import { useCurrentUserKeyEnhancer } from 'src/common/hooks/useCurrentUserKeyEnhancer';
import { latestCoursesLimit } from 'src/config/limits';

export const useLatestCoursesQuery = (limit = latestCoursesLimit) => {
  const { key, isAuthenticated } = useCurrentUserKeyEnhancer([QueryKey.AvailableCourses, limit]);

  return useQuery(key, () => getAvailableCourses(isAuthenticated, limit));
};
