import { useQuery } from 'react-query';
import { QueryKey } from 'src/config/queryKey';
import { useCurrentUserKeyEnhancer } from 'src/common/hooks/useCurrentUserKeyEnhancer';
import { getAvailableCourses } from 'src/common/services/courseBundle';

export const useLatestCourseBundlesQuery = () => {
  const { key } = useCurrentUserKeyEnhancer([QueryKey.AvailableCourseBundles]);

  return useQuery(key, () => getAvailableCourses());
};
