import { createAction } from 'src/lib/createAction';
import { ElearningDTO } from 'src/common/models/elearning';

export const courseBundleElearningsActions = {
  addElearnings: createAction('courseBundle/courseBundleElearnings/addElearnings')<
    ElearningDTO[]
  >(),
  clear: createAction('courseBundle/courseBundleElearnings/clear')(),
  setElearnings: createAction('courseBundle/courseBundleElearnings/setElearnings')<
    ElearningDTO[]
  >(),
  setTotal: createAction('courseBundle/courseBundleElearnings/setTotal')<number>(),
};
