import React, { FC } from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import { colors, shadows } from 'src/config/colors';
import styled from 'styled-components';

const Container = styled.div`
  border-radius: 6px;
  box-shadow: ${shadows.drop1};
  overflow: hidden;
`;

export const CourseCardFeaturedSkeleton: FC<IContentLoaderProps> = (props) => (
  <Container>
    <ContentLoader
      height={330}
      speed={2}
      viewBox="0 0 686 330"
      backgroundColor={colors.darkGrey}
      foregroundColor={colors.grey}
      style={{ display: 'block', width: '100%' }}
      preserveAspectRatio="xMidYMid slice"
      uniqueKey="CourseCardFeaturedSkeleton"
      {...props}
    >
      <rect x="0" y="0" rx="6" ry="6" width="686" height="330" />
    </ContentLoader>
  </Container>
);
