import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { homepageTabName } from 'src/common/models/event';
import { CleanButton } from 'src/common/components/Button';
import { typography } from 'src/config/typography';
import { useDispatch, useSelector } from 'react-redux';
import { homepageViewTypeSelector } from 'src/state/homepage/homepageSelectors';
import { homepageActions } from 'src/state/homepage/homepageActions';
import { media } from 'src/config/breakpoints';
import { CourseType } from 'src/common/models/courseType';

const Tab = styled(CleanButton)<{ tabType: CourseType; active: boolean }>`
  border-radius: 0.5rem 0.5rem 0 0;
  font-family: ${typography.fontFamilyDefault};
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.15em;
  line-height: 1rem;
  padding: 0.75rem 1.25rem 0.6875rem;
  text-align: center;
  text-decoration-line: underline;
  text-transform: uppercase;
  width: 11.375rem;

  &:not(:first-child) {
    margin-left: -0.8125rem;
    padding: 0.75rem 1.25rem 0.6875rem 2.0625rem;
  }

  ${({ tabType, active }) => {
    switch (tabType) {
      case CourseType.StandardCourse:
        return css`
          background: #a16c80;
          color: ${colors.white};
          ${active &&
          css`
            color: ${colors.pfp3bis};
          `}
        `;
      case CourseType.ElearningCourse:
        return css`
          background: #a16c80;
          color: ${colors.white};
          ${active &&
          css`
            color: ${colors.pfp3bis};
          `}
        `;
      case CourseType.CourseBundle:
        return css`
          background: #a16c80;
          color: ${colors.white};
          ${active &&
          css`
            color: ${colors.pfp3bis};
          `}
        `;
    }
  }};

  ${({ active }) =>
    active &&
    css`
      background: ${colors.white};
      z-index: 10;
    `};
`;

const Container = styled.div`
  background: ${colors.beige};
  display: flex;

  ${media.w.min.px768(css`
    margin: 0 -1.5rem;
  `)};
`;

const courseTypes = [
  CourseType.StandardCourse,
  CourseType.ElearningCourse,
  CourseType.CourseBundle,
];

export const HomepageViewNavigationBar: FC = () => {
  const activeHomepageViewType = useSelector(homepageViewTypeSelector);
  const dispatch = useDispatch();

  const handleViewChange = (viewType: CourseType) => {
    dispatch(homepageActions.setViewType(viewType));
  };

  return (
    <Container>
      {courseTypes.map((tabType) => (
        <Tab
          key={tabType}
          onClick={() => handleViewChange(tabType as CourseType)}
          tabType={tabType as CourseType}
          active={tabType === activeHomepageViewType}
        >
          {homepageTabName[tabType]}
        </Tab>
      ))}
    </Container>
  );
};
