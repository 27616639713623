import { RootState } from 'src/state/reducer';
import { createSelector } from 'reselect';
import { HomepageState } from 'src/state/homepage/homepageReducer';

export const homepageState = (state: RootState) => state.homepage;

export const homepageViewTypeSelector = createSelector(
  homepageState,
  (state: HomepageState) => state.homepageViewType,
);
