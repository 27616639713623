import avatar1 from 'src/assets/images/testimonial-avatar-person1.jpg';
import avatar2 from 'src/assets/images/testimonial-avatar-person2.jpg';
import avatar3 from 'src/assets/images/testimonial-avatar-person3.jpg';
import avatar4 from 'src/assets/images/testimonial-avatar-person4.jpg';
import React, { ReactNode } from 'react';

export interface TestimonialInfo {
  name: string;
  description: string;
  location: string;
}

export interface TestimonialType {
  avatarImg: StaticImageData;
  info: TestimonialInfo;
  insightPoints: string[];
  opinion: ReactNode;
}

export const sampleTestimonials: TestimonialType[] = [
  {
    avatarImg: avatar1,
    info: {
      name: 'Zofia Szybowska',
      description: 'Prowadzi firmę DekoGrafia',
      location: 'Szczecin, zachodniopomorskie',
    },
    insightPoints: [
      'założyć bezproblemowo działalność gospodarczą,',
      'sprawnie prowadzić firmowe konta reklamowe,',
      'nawiązać współpracę z lokalnymi przedsiębiorcami',
    ],
    opinion: (
      <>
        Serdecznie polecam, pełen profesjonalizm! Uczęszczałam na szkolenia zarówno stacjonarnie jak
        i online. <br /> Podczas kilku godzin można posiąść dużo wiedzy, poznać wiele zagadnień,
        otrzymać poradę, odpowiedzi na pytania, a przy okazji poznać osoby uczęszczające na kursy,
        dzięki czemu można wymienić się z nimi różnymi doświadczeniami jak podjąć współprace. <br />
        Oprócz teorii stosowane są różnego rodzaju ćwiczenia, które pobudzają do myślenia i
        działania w grupie. Co ważne po zajęciach otrzymujemy materiały, które dotyczyły danego
        szkolenia.
      </>
    ),
  },
  {
    avatarImg: avatar2,
    info: {
      name: 'Kamila Budzyńska',
      description: 'prowadzi agroturystykę Dom nad Stawem',
      location: 'Karpno, woj. zachodniopomorskie',
    },
    insightPoints: [
      'poznać zasady pisania bloga firmowego,',
      'nauczyć się ustawiania płatnych kampanii reklamowych,',
      'zbudować markę mojej firmy',
    ],
    opinion: (
      <>
        Polecam wszystkim szkolenia organizowane przez PFP - wiedza jest przekazywana w kompleksowy
        i jednocześnie prosty dla użytkownika sposób.
        <br /> Podczas zajęć poruszane są aspekty zarówno teoretyczne jak i praktyczne, które są
        dodatkowo omawiane na przykładach firm uczestników szkolenia. <br />
        Przygotowanie szkolenia jak i sposób prowadzenia jest zawsze na najwyższym poziomie.
      </>
    ),
  },
  {
    avatarImg: avatar3,
    info: {
      name: 'Jarosław Ziajka',
      description: 'prowadzi firmę Euroexpert Jarosław Ziajka Rzeczoznawstwo',
      location: 'Dochodzenie odszkodowań',
    },
    insightPoints: [
      'rozszerzyć obszar działalności mojej firmy,',
      'poznać ciekawych ludzi z innych branż,',
      'nabyć nowe umiejętności przydatne przy prowadzeniu swojego biznesu',
    ],
    opinion: (
      <>
        Brałem wielokrotnie udział w szkoleniach organizowanych przez PFP zarówno w siedzibie
        Fundacji jak i ONLINE. Tematyka szkoleń jest bardzo zróżnicowana. Wszystkie szkolenia, w
        których uczestniczyłem prowadzone były profesjonalnie w miłej atmosferze przez osoby z danej
        branży. <br /> Dzięki zdobytej wiedzy rozszerzyłem obszar działalności swojej firmy,
        poznałem ciekawych ludzi z innych branż, co również pomogło mi w rozwoju. <br /> Polecam
        szkolenia zarówno osobom będącym na początku przygody z własnym biznesem, jak i właścicielom
        firm, w celu podnoszenia kwalifikacji zarówno swoich jak i oddelegowanych pracowników.
      </>
    ),
  },
  {
    avatarImg: avatar4,
    info: {
      name: 'Wojciech Trukszyn',
      description: 'prowadzi firmę Trukszyn IT',
      location: 'Szczecin Zachodniopomorskie',
    },
    insightPoints: [
      'zagłębić temat PPK,',
      'przystosować wymogi RODO do procedur zgodnych z profilem mojej firmy,',
      'rozwinąć kompetencje pracowników',
    ],
    opinion: (
      <>
        Ze szkoleń Fundacji korzystam od wielu lat. Bardzo chwalę sobie najnowszą formułę w formie
        on-line, dzięki której mogę uczestniczyć w nich z każdego miejsca. Dzięki szkoleniom mogę
        być zawsze na bieżąco ze wszystkimi istotnymi aspektami ważnymi z punktu widzenia
        właściciela firmy. <br /> Każde szkolenie przygotowane jest profesjonalnie i z ogromnym
        zaangażowaniem wykładowcy. Polecam każdemu – wartościowa wiedza dostępna od ręki –
        bezpłatnie, bez żadnych ukrytych opłat. Korzystam zarówno ja, jak i moi pracownicy.
      </>
    ),
  },
];
