import { QueryObserverOptions, useQuery } from 'react-query';
import { QueryKey } from 'src/config/queryKey';
import { getAvailableCourses } from 'src/common/services/course';
import { useCurrentUserKeyEnhancer } from 'src/common/hooks/useCurrentUserKeyEnhancer';
import { Course } from 'src/common/models/course';
import { coursesByCategoryLimit } from 'src/config/limits';

export const useCoursesByCategoryQuery = (
  categoryId: number,
  limit = coursesByCategoryLimit,
  options?: QueryObserverOptions<Course[]>,
) => {
  const { key, isAuthenticated } = useCurrentUserKeyEnhancer([
    QueryKey.AvailableCourses,
    limit,
    { categoryId },
  ]);

  return useQuery(key, () => getAvailableCourses(isAuthenticated, limit, { categoryId }), {
    ...options,
  });
};
