import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import homepageAboutUsOnlineDesktop from 'src/assets/images/homepage-about-us-desktop-final.png';
import homepageAboutUsOnlineMobile from 'src/assets/images/homepage-about-us-mobile-final.png';
import homepageAboutUsElearningDesktop from 'src/assets/images/homepage-about-us-elearning-desktop-tmp.png';
import homepageAboutUsCourseBundleDesktop from 'src/assets/images/homepage-about-us-course-bundle-desktop.png';
import { media, widthBreakpoints } from 'src/config/breakpoints';
import { Button } from 'src/common/components/Button';
import { AboutUsRoute } from 'src/public/publicRoutes';
import { colors } from 'src/config/colors';
import { typography } from 'src/config/typography';
import { MediaQuery } from 'src/common/migration/MediaQuery';
import { useSelector } from 'react-redux';
import { homepageViewTypeSelector } from 'src/state/homepage/homepageSelectors';
import { CourseType } from 'src/common/models/courseType';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';
import Image from 'next/image';

const Container = styled.div<{ viewType: CourseType }>`
  display: grid;
  grid-template-areas:
    'image'
    'headline'
    'title'
    'button';
  justify-items: center;
  margin: 0 auto;
  max-width: 31.25rem;
  padding-bottom: 1.25rem;
  text-align: center;

  ${media.w.min.px768(css`
    grid-template-areas:
      'headline image'
      'title image'
      'labels image'
      'button image';
    grid-template-columns: 54% 46%;
    grid-template-rows: max-content max-content auto;
    justify-items: start;
    max-width: none;
    padding: 4.6875rem 0.5rem 1.25rem;
    text-align: left;
  `)};

  ${({ viewType }) =>
    viewType !== CourseType.StandardCourse &&
    css`
      ${media.w.min.px768(css`
        grid-template-areas:
          'headline image'
          'title image'
          'button image';
      `)};
    `}

  ${media.w.min.px992(css`
    grid-template-columns: 56% 44%;
  `)};

  ${media.w.min.px1200(css`
    padding: 7.125rem 0 1.25rem 1.25rem;
  `)};
`;

const Headline = styled.div`
  color: ${colors.red};
  font-weight: 600;
  grid-area: headline;
  letter-spacing: 0.15em;
  text-transform: uppercase;
`;

const AboutUsImage = styled.div`
  grid-area: image;
  z-index: -1;
  margin-top: 0.5rem;

  ${media.w.min.px768(css`
    justify-self: end;
    margin-top: 0;
  `)};

  ${media.w.min.px992(css`
    width: 120%;
  `)};

  ${media.w.min.px1200(css`
    margin-top: -0.25rem;
    width: 125%;
  `)};

  img {
    max-width: 100%;

    ${media.w.min.px992(css`
      width: 100%;
    `)};
  }
`;

const ElearningImage = styled(AboutUsImage)`
  ${media.w.min.px1200(css`
    margin-right: 0.5rem;
  `)};
`;

const OnlineImage = styled(AboutUsImage)`
  ${media.w.min.px1200(css`
    margin-right: -0.625rem;
  `)};
`;

const ImageDesktop = styled.div`
  width: 100%;

  ${media.w.max.px768(css`
    display: none;
  `)};
`;

const ImageMobile = styled.div`
  ${media.w.min.px768(css`
    display: none;
  `)};
`;

const StyledButton = styled(Button)`
  align-self: start;
  grid-area: button;
`;

const LabelBox = styled.div`
  grid-area: labels;
  display: flex;
  gap: 17px;
  margin-bottom: 2.1875rem;
`;

const CourseTypeLabel = styled.div`
  padding: 5px;
  border-radius: 0.375rem;
  border: 1px solid ${colors.pfp3bis};
  height: fit-content;
  font-family: ${typography.fontFamilyDefault};
  font-size: 0.625rem;
  line-height: 0.875rem;
  color: ${colors.pfp3bis};
`;

const Title = styled.div`
  font-family: ${typography.fontFamilyHeaders};
  font-size: 1.5rem;
  grid-area: title;
  line-height: 1.875rem;
  margin: 0.9375rem 0;

  ${media.w.min.px768(css`
    font-size: 2.3125rem;
    line-height: 2.875rem;
  `)};
`;

const NoWrap = styled.span`
  white-space: nowrap;
`;

export const aboutUsTitleMapper = {
  [CourseType.StandardCourse]: (
    <>Wybierz i&nbsp;zdobywaj wiedzę dzięki bezpłatnym szkoleniom z&nbsp;PFP</>
  ),
  [CourseType.ElearningCourse]: (
    <>
      Zdobywaj&nbsp;wiedzę dzięki bezpłatnym <NoWrap>e-learningom</NoWrap> w&nbsp;dowolnym momencie
    </>
  ),
  [CourseType.CourseBundle]: <>Rozwijaj swoje kompetencje dzięki&nbsp;bezpłatnym kursom</>,
};

export const HomepageAboutUs: FC = () => {
  const selectedViewType = useSelector(homepageViewTypeSelector);

  return (
    <Container viewType={selectedViewType}>
      {selectedViewType === CourseType.ElearningCourse && (
        <ElearningImage>
          <ImageDesktop>
            <Image alt="" src={homepageAboutUsElearningDesktop} />
          </ImageDesktop>
          <ImageMobile>
            <Image alt="" src={homepageAboutUsElearningDesktop} />
          </ImageMobile>
        </ElearningImage>
      )}

      {selectedViewType === CourseType.StandardCourse && (
        <OnlineImage>
          <ImageDesktop>
            <Image alt="" src={homepageAboutUsOnlineDesktop} />
          </ImageDesktop>
          <ImageMobile>
            <Image alt="" src={homepageAboutUsOnlineMobile} />
          </ImageMobile>
        </OnlineImage>
      )}

      {selectedViewType === CourseType.CourseBundle && (
        <OnlineImage>
          <ImageDesktop>
            <Image alt="" src={homepageAboutUsCourseBundleDesktop} />
          </ImageDesktop>
          <ImageMobile>
            <Image alt="" src={homepageAboutUsCourseBundleDesktop} />
          </ImageMobile>
        </OnlineImage>
      )}

      <Headline>Platforma bezpłatnych szkoleń</Headline>

      <Title>{aboutUsTitleMapper[selectedViewType]}</Title>

      {selectedViewType === CourseType.StandardCourse && (
        <MediaQuery minWidth={widthBreakpoints.px992}>
          <LabelBox>
            <CourseTypeLabel>Szkolenia online</CourseTypeLabel>
            <CourseTypeLabel>E-learning</CourseTypeLabel>
          </LabelBox>
        </MediaQuery>
      )}
      <StyledButton as={GatsbyLink} to={AboutUsRoute}>
        Zobacz dlaczego warto
      </StyledButton>
    </Container>
  );
};
