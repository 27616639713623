import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { SectionTitle } from 'src/common/components/SectionTitle';
import { media } from 'src/config/breakpoints';
import { SwiperSlide } from 'swiper/react';
import { CourseCardSwiper, NavigationButton } from 'src/common/components/CourseCardSwiper';
import { Testimonial } from 'src/common/components/Testimonial';
import { typography } from 'src/config/typography';
import { layers } from 'src/config/layers';
import { sampleTestimonials } from 'src/public/components/homepage/config-testimonials';
import { SwiperOptions } from 'swiper';

const Container = styled.div`
  padding: 1.875rem 0 3.125rem;

  ${media.w.min.px768(css`
    padding: 2.5rem 0;
  `)};

  ${media.w.min.px992(css`
    padding-bottom: 1.875rem;
  `)};
`;

const Header = styled.div`
  text-align: center;

  ${media.w.min.px992(css`
    padding-left: 2.8125rem;
    text-align: left;
  `)};
`;

const StyledCourseCardSwiper = styled(CourseCardSwiper)`
  margin: 0 -0.75rem;

  ${media.w.between.px768.px992(css`
    margin: 0 -1.875rem;
    overflow: hidden;
  `)};

  ${NavigationButton} {
    ${media.w.max.px1200(css`
      display: none;
    `)};
  }

  .swiper {
    ${media.w.min.px768(css`
      padding-bottom: 3.3125rem;
    `)};
  }

  .swiper-scrollbar {
    ${media.w.min.px1200(css`
      display: none;
    `)};
  }

  .swiper-wrapper {
    align-items: flex-start;
  }
`;

const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: 0.9375rem;

  ${media.w.min.px768(css`
    margin-bottom: 1.25rem;
  `)};
`;

const Title = styled.div`
  font-family: ${typography.fontFamilyHeaders};
  font-size: 1.5rem;
  line-height: 1.875rem;
  margin-bottom: -0.5rem;
  position: relative;
  z-index: ${layers.contentBottom};

  ${media.w.min.px768(css`
    margin-bottom: 0;
  `)};
`;

const swiperOptions: SwiperOptions = {
  slidesPerView: 1,
};

export const HomepageTestimonials: FC = () => (
  <Container>
    <Header>
      <StyledSectionTitle>Jak aktywizujemy przedsiębiorców</StyledSectionTitle>
      <Title>Poznaj historie uczestników naszych szkoleń</Title>
    </Header>

    <StyledCourseCardSwiper options={swiperOptions}>
      {sampleTestimonials.map((item, index) => (
        <SwiperSlide key={index}>
          <Testimonial testimonial={item} />
        </SwiperSlide>
      ))}
    </StyledCourseCardSwiper>
  </Container>
);
