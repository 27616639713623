import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { homepageViewTypeSelector } from 'src/state/homepage/homepageSelectors';
import { HomepageLatestOnlineCourses } from 'src/public/components/homepage/HomepageLatestCourses/HomepageLatestOnlineCourses';
import { HomepageLatestElearningCourses } from 'src/public/components/homepage/HomepageLatestCourses/HomepageLatestElearningCourses';
import { HomepageLatestBoundleCourses } from 'src/public/components/homepage/HomepageLatestCourses/HomepageLatestBundleCourses';
import { CourseType } from 'src/common/models/courseType';

export const HomepageLatestCourses: FC = () => {
  const selectedViewType = useSelector(homepageViewTypeSelector);

  if (selectedViewType === CourseType.StandardCourse) {
    return <HomepageLatestOnlineCourses />;
  } else if (selectedViewType === CourseType.ElearningCourse) {
    return <HomepageLatestElearningCourses />;
  } else {
    return <HomepageLatestBoundleCourses />;
  }
};
