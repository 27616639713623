import React, { FC } from 'react';
import RRMediaQuery, { MediaQueryProps } from 'react-responsive';
import { useClientReady } from 'src/lib/SSR/ClientOnly/ClientOnly';

export const MediaQuery: FC<MediaQueryProps> = (props) => {
  const isClient = useClientReady();
  const ssrProps = isClient ? props : { all: true };
  return (
    <RRMediaQuery {...ssrProps}>
      <>{props.children}</>
    </RRMediaQuery>
  );
};
