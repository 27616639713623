import React from 'react';
import { Layout } from 'src/common/components/Layout';
import { LayoutBox } from 'src/common/components/LayoutBox';
import { HomepageAboutUs } from 'src/public/components/homepage/HomepageAboutUs';
import { HomepageTestimonials } from 'src/public/components/homepage/HomepageTestimonials';
import { MobileCategoriesWidget } from 'src/common/components/MobileCategoriesWidget';
import { useResetRedirectUrl } from 'src/common/guards/useResetRedirectUrl';
import { LayoutPanelCategories } from 'src/common/components/Layout/LayoutPanelCategories';
import { Speakers } from 'src/common/components/Speakers';
import { HowItWorks } from 'src/common/components/HowItWorks';
import { HomepageViewNavigationBar } from 'src/public/components/homepage/HomepageViewNavigationBar';
import { HomepageLatestCourses } from 'src/public/components/homepage/HomepageLatestCourses';
import { HomepageCategory } from 'src/public/components/homepage/HomepageCategory';
import { VendorGetStaticProps } from 'vendor/utils/VendorGetStaticProps';
import {
  entrepreneurCategoryCode,
  getCategoryIdByCode,
  mapToCategory,
} from 'src/common/models/category';
import { QueryKey } from 'src/config/queryKey';
import { getAvailableCourses, getCourseCategoriesDTO } from 'src/common/services/course';
import { coursesByCategoryLimit, latestCoursesLimit, randomSpeakersLimit } from 'src/config/limits';
import { getRandomSpeakers } from 'src/common/services/speaker';
import { buildUnauthenticatedUserKeyEnhancer } from 'src/common/hooks/useCurrentUserKeyEnhancer';

const IndexPage = () => {
  useResetRedirectUrl();

  return (
    <Layout leftPanel={<LayoutPanelCategories />} widgetPadding={65} scrollToTopButton>
      <LayoutBox tabletBackground>
        <HomepageViewNavigationBar />
        <HomepageAboutUs />
        <HomepageLatestCourses />
      </LayoutBox>

      <HomepageCategory />
      <HowItWorks />
      <Speakers />
      <HomepageTestimonials />

      <MobileCategoriesWidget />
    </Layout>
  );
};

export async function getSitemap(): Promise<SitemapEntry[]> {
  return [
    {
      changefreq: 'daily',
    },
  ];
}

export const getStaticProps = VendorGetStaticProps(async (ctx) => {
  const { key: latestCoursesKey } = buildUnauthenticatedUserKeyEnhancer([
    QueryKey.AvailableCourses,
    latestCoursesLimit,
  ]);
  await ctx.queryClient.prefetchQuery(latestCoursesKey, () =>
    getAvailableCourses(false, latestCoursesLimit),
  );

  const categories = await getCourseCategoriesDTO();
  const entrepreneurCategoryId = getCategoryIdByCode(
    categories.map(mapToCategory) ?? [],
    entrepreneurCategoryCode,
  );
  const { key: coursesByCategoryKey } = buildUnauthenticatedUserKeyEnhancer([
    QueryKey.AvailableCourses,
    coursesByCategoryLimit,
    { categoryId: entrepreneurCategoryId },
  ]);
  await ctx.queryClient.prefetchQuery(coursesByCategoryKey, () =>
    getAvailableCourses(false, coursesByCategoryLimit, {
      categoryId: entrepreneurCategoryId || undefined,
    }),
  );

  await ctx.queryClient.prefetchQuery([QueryKey.RandomSpeakers, randomSpeakersLimit], () =>
    getRandomSpeakers(randomSpeakersLimit),
  );

  return {
    props: {},
  };
});

export default IndexPage;
