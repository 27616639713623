import TestimonialQuotemarkMobileSvg from 'src/assets/images/testimonial-quotemark-mobile.svg';
import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';

export const TestimonialQuotemarkMobile = styled(TestimonialQuotemarkMobileSvg)`
  height: 2.5625rem;

  ${media.w.min.px768(css`
    display: none;
  `)};
`;
