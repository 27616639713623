import { QueryFunctionContext, useInfiniteQuery, useQueryClient } from 'react-query';
import { useEffect } from 'react';
import { QueryKey } from 'src/config/queryKey';
import { useDispatch } from 'react-redux';
import { unwrap } from 'src/common/models/page';
import { useCurrentUserKeyEnhancer } from 'src/common/hooks/useCurrentUserKeyEnhancer';
import { getCourseBundleEvents } from 'src/common/services/courseBundle';
import { courseBundleEventsActions } from 'src/courseBundle/state/courseBundleEvents/courseBundleEventsActions';

export const courseBundleEventsPageSize = 3;

export const useCourseBundleEventsQuery = (courseBundleId?: number) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { key } = useCurrentUserKeyEnhancer([QueryKey.CourseBundleEvents, courseBundleId]);

  useEffect(() => {
    return () => {
      queryClient.setQueryData(key, () => ({
        pages: [],
        pageParams: undefined,
      }));
    };
  }, []);

  return useInfiniteQuery(
    key,
    (context: QueryFunctionContext) => getCourseBundleEvents(context, courseBundleId!),
    {
      enabled: !!courseBundleId,
      getNextPageParam: (lastPage, allPages) => {
        return lastPage && lastPage.totalResult > allPages.length * courseBundleEventsPageSize
          ? allPages.length + 1
          : false;
      },
      onSuccess: (data) => {
        if (data.pages.length <= 0) {
          return;
        }

        data.pages.length === 1
          ? dispatch(courseBundleEventsActions.setEvents(unwrap(data.pages[0])))
          : dispatch(
              courseBundleEventsActions.addEvents(unwrap(data.pages[data.pages.length - 1])),
            );
      },
    },
  );
};
